import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import store from "@/store";
import { ErrorResponse } from "@/services/axios/error";
import {
  IFuelScopeTwoRenewableEnergiesResponse,
  IScopeTwoChart,
  IScopeTwoDataChart,
  IScopeTwoGeneratedEnergies,
  IScopeTwoGeneratedEnergiesResponse,
  IScopeTwoGeneratedEnergiesUploadRequest,
  IScopeTwoRenewableEnergiesRequest,
} from "@/models/ScopeTwoEmissions";
import { S2RPAPI } from "@/services/s2rp";
import { ScopeTwoEmissionAPI } from "@/services/scopeTwoEmissions";
import { covertDataChartMonthValue } from "@/utils/helpers/convertDataChart";
import { ElMessage } from "element-plus/lib/components/message";
const name = "ScopeTwoRenewableEnergies";

if (store.state[name]) {
  store.unregisterModule(name);
}
@Module({ dynamic: true, name, namespaced: true, store })
class S2RenewableEnergyModule extends VuexModule {
  private errorGetRenewableEnergies = {} as ErrorResponse;
  private isLoadingGetRenewableEnergies = false;
  private dataRenewableEnergies = [] as IScopeTwoGeneratedEnergiesResponse[];
  private isLoadingCreateRenewableEnergies = false;
  private errorCreateRenewableEnergies = {} as ErrorResponse;
  private errorScopeTwoEnergyLoad = {} as ErrorResponse;
  private dataScopeTwoEnergyLoad = [] as IScopeTwoDataChart[][];
  private isLoadingScopeTwoEmission = false as boolean;
  private dataFuelRenewableEnergies = [] as any;
  get isLoadingGetRenewableEnergiesGetter() {
    return this.isLoadingGetRenewableEnergies;
  }

  get dataRenewableEnergiesGetter() {
    return this.dataRenewableEnergies;
  }

  @Mutation
  setLoadingRenewableEnergyRECs(isLoading: boolean) {
    this.isLoadingGetRenewableEnergies = isLoading;
  }
  @Mutation
  getRenewableEnergySuccess(data: any) {
    const { Collection, ...rest } = data;
    this.dataRenewableEnergies = Collection;
    this.isLoadingGetRenewableEnergies = false;
  }
  @Mutation
  getRenewableEnergyFailure(error: ErrorResponse) {
    this.errorGetRenewableEnergies = error;
    this.isLoadingGetRenewableEnergies = false;
  }
  @Action({ rawError: true })
  async getRenewableEnergies(payload?: IScopeTwoGeneratedEnergies) {
    const serviceS2RenewableEnergiesAPI = new S2RPAPI();
    const { data, error } =
      await serviceS2RenewableEnergiesAPI.getScopeTwoRenewableEnergies(payload);
    if (error) {
      this.getRenewableEnergyFailure(error);
      return { error };
    } else {
      this.getRenewableEnergySuccess(data);
    }
  }

  get dataFuelRenewableEnergiesGetter() {
    return this.dataFuelRenewableEnergies;
  }
  @Mutation
  setFuelLoadingRenewableEnergyRECs(isLoading: boolean) {
    this.isLoadingGetRenewableEnergies = isLoading;
  }
  @Mutation
  getFuelRenewableEnergySuccess(data: any) {
    const dataFuel = data.map((item: string, index: number) => {
      return { Value: index, Name: item };
    });
    this.dataFuelRenewableEnergies = dataFuel;
    this.isLoadingGetRenewableEnergies = false;
  }
  @Mutation
  getFuelRenewableEnergyFailure(error: ErrorResponse) {
    this.errorGetRenewableEnergies = error;
    this.isLoadingGetRenewableEnergies = false;
  }
  @Action({ rawError: true })
  async getFuelRenewableEnergies() {
    const serviceS2RenewableEnergiesAPI = new S2RPAPI();
    const { data, error } =
      await serviceS2RenewableEnergiesAPI.getFuelScopeTwoRenewableEnergies();
    if (error) {
      this.getFuelRenewableEnergyFailure(error);
      return { error };
    } else {
      this.getFuelRenewableEnergySuccess(data);
    }
  }

  @Mutation
  setLoadingCreateRenewableEnergyRECs(isLoading: boolean) {
    this.isLoadingCreateRenewableEnergies = isLoading;
  }
  @Mutation
  getRenewableCreateEnergySuccess(data: any) {
    this.errorCreateRenewableEnergies = {} as ErrorResponse;
  }
  @Mutation
  getRenewableCreateEnergyFailure(error: ErrorResponse) {
    this.errorCreateRenewableEnergies = error;
    this.isLoadingCreateRenewableEnergies = false;
  }

  @Mutation
  createRenewableEnergyFailure(error: ErrorResponse) {
    ElMessage.error(error.message);
  }
  @Mutation
  createRenewableEnergySuccess() {
    ElMessage({
      message: "Upload Renewable Energies success.",
      grouping: true,
      type: "success",
    });
  }

  @Action({ rawError: true })
  async createRenewableEnergy(payload: IScopeTwoRenewableEnergiesRequest) {
    const serviceS2RenewableEnergiesAPI = new S2RPAPI();
    const { data, error } =
      await serviceS2RenewableEnergiesAPI.postScopeTwoRenewableEnergies(
        payload
      );
    if (error) {
      this.createRenewableEnergyFailure(error);
      return { error };
    } else {
      this.createRenewableEnergySuccess();
      return data;
    }
  }

  @Mutation
  setLoadingUploadFileRenewableEnergyRECs(isLoading: boolean) {
    this.isLoadingCreateRenewableEnergies = isLoading;
  }
  @Mutation
  setRenewableUploadFileEnergySuccess(data: any) {
    this.errorCreateRenewableEnergies = {} as ErrorResponse;
  }

  @Mutation
  setRenewableUploadFileEnergyFailure(data: any) {
    this.errorCreateRenewableEnergies = {} as ErrorResponse;
    this.isLoadingCreateRenewableEnergies = false;
  }
  @Action({ rawError: true })
  async uploadFileRenewableEnergy(
    payload: IScopeTwoGeneratedEnergiesUploadRequest
  ) {
    this.setLoadingUploadFileRenewableEnergyRECs(true);
    const serviceS2RenewableEnergiesAPI = new S2RPAPI();
    const { data, error } =
      await serviceS2RenewableEnergiesAPI.uploadDocumentScopeTwoRenewableEnergies(
        payload
      );
    if (error) {
      this.getRenewableEnergyFailure(error);
      return { error };
    } else {
      this.setRenewableUploadFileEnergySuccess(data);
      return data;
    }
  }

  get dataScopeTwoEnergyLoadGetter() {
    return this.dataScopeTwoEnergyLoad;
  }

  get isLoadingScopeTwoEmissionGetter() {
    return this.isLoadingScopeTwoEmission;
  }
  @Mutation
  setIsLoadingS2Load(isLoading: boolean) {
    this.isLoadingScopeTwoEmission = isLoading;
  }
  @Mutation
  setS2LoadEnergyError(error: ErrorResponse) {
    this.errorScopeTwoEnergyLoad = error;
    this.isLoadingScopeTwoEmission = false;
  }

  @Mutation
  setS2LoadEnergySuccess(dataS2LoadEnergy: IScopeTwoDataChart[][]) {
    this.dataScopeTwoEnergyLoad = dataS2LoadEnergy;
    this.isLoadingScopeTwoEmission = false;
  }
  @Action({ rawError: true })
  async fetchS2RenewablePercentages() {
    this.setIsLoadingS2Load(true);
    const serviceScopeTwoEmissionAPI = new ScopeTwoEmissionAPI();
    const { data, error } =
      await serviceScopeTwoEmissionAPI.getScopeTwoEmissionsRenewablePercentages(
        { Year: new Date().getFullYear() }
      );
    if (error) {
      this.setS2LoadEnergyError(error);
      return null;
    } else {
      const dataConvert = covertDataChartMonthValue(
        data.RenewableEnergyDetails
      );
      this.setS2LoadEnergySuccess(dataConvert);
      return data.RenewableEnergies;
    }
  }
}
export default getModule(S2RenewableEnergyModule);
