import {
  IGetRetireRequestDTO,
  IGetRetireResponseDTO,
  IRetireRECs,
} from "@/models/Retire";
import { ErrorResponse } from "@/services/axios/error";
import { IResponse } from "@/services/axios/entities";
import { ElMessage } from "element-plus/lib/components/message";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import { RetireAPI } from "@/services/retire";
import store from "@/store";
import ScopeTwoEnergiesModule from "@ems/containers/Dashboard/TheForm/S2RP.module";
import {
  IScopeTwoDataChart,
  IScopeTwoRequestTotal,
} from "@/models/ScopeTwoEmissions";
import { covertDataChartMonthValue } from "@/utils/helpers/convertDataChart";
import S2RenewableEnergyModule from "@ems/containers/ScopeTwoEmission/Table/S2RenewableEnergy.module";

const name = "RetireFormModule";

if (store.state[name]) {
  store.unregisterModule(name);
}

@Module({ dynamic: true, name, namespaced: true, store })
class RetireModule extends VuexModule {
  private errorGetRetireRECs = {} as ErrorResponse;
  private isLoadingGetRetireRECs = false;
  private dataGetRetireRECs = [] as IGetRetireResponseDTO[][];
  private renewablesEnergyChart = [] as IScopeTwoDataChart[][];
  get isLoadingGetRetireRECsGetter() {
    return this.isLoadingGetRetireRECs;
  }

  get getRetireRECsGetter() {
    return this.dataGetRetireRECs;
  }

  get getRenewablesEnergyChartGetter() {
    return this.renewablesEnergyChart;
  }
  @Mutation
  emptyRetireRecs() {
    this.dataGetRetireRECs = [];
    this.renewablesEnergyChart = [];
  }
  @Mutation
  setLoadingGetRetireRECs(isLoading: boolean) {
    this.isLoadingGetRetireRECs = isLoading;
  }
  @Mutation
  getRetireRECsSuccess(data: any) {
    this.renewablesEnergyChart = covertDataChartMonthValue(data[1]);
    this.dataGetRetireRECs = covertDataChartMonthValue(data[0]);
  }
  @Mutation
  getRetireRECsFailure(error: ErrorResponse) {
    this.errorGetRetireRECs = error;
    this.isLoadingGetRetireRECs = false;
    ElMessage.error(error);
  }
  @Action({ rawError: true })
  async getRetireRECs(payload: IGetRetireRequestDTO) {
    const serviceRenewableOptionAPI = new RetireAPI();
    const { data, error } = await serviceRenewableOptionAPI.getRetireRECs(
      payload
    );
    if (error) {
      this.getRetireRECsFailure(error);
      return { error };
    } else {
      return data;
    }
  }

  @Action({ rawError: true })
  async getRetireRecAndRenewable(payload: any) {
    this.setLoadingGetRetireRECs(true);
    const values = await Promise.all([
      this.getRetireRECs(payload),
      S2RenewableEnergyModule.fetchS2RenewablePercentages(),
    ]);
    this.getRetireRECsSuccess(values);
    this.setLoadingGetRetireRECs(false);
  }
}
export default getModule(RetireModule);
