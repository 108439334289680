import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import store from "@/store";
import { ErrorResponse } from "@/services/axios/error";
import { DevicesAPI } from "@/services/device";
import { ServicesDTO } from "@/models/Service";
const name = "Device";

if (store.state[name]) {
  store.unregisterModule(name);
}
@Module({ dynamic: true, name, namespaced: true, store })
class DeviceModule extends VuexModule {
  private errorDevices = {} as ErrorResponse;
  private isLoadingDevices = false;
  private dataDevices = [] as ServicesDTO[];

  private errorCreateDevice = {} as ErrorResponse;
  private isLoadingCreateDevice = false;
  private dataCreateDevices = "" as string;
  get isLoadingDevicesGetter() {
    return this.isLoadingDevices;
  }

  get dataDevicesGetter() {
    return this.dataDevices;
  }

  @Mutation
  setLoadingDevices(isLoading: boolean) {
    this.isLoadingDevices = isLoading;
  }
  @Mutation
  getDevicesSuccess(data: ServicesDTO[]) {
    this.dataDevices = data;
    this.dataDevices.unshift({
      Name: "Add new device",
      Id: "All",
    });
  }
  @Mutation
  getDevicesFailure(error: ErrorResponse) {
    this.errorDevices = error;
    this.isLoadingDevices = false;
  }
  @Action({ rawError: true })
  async getDevices(payload: { FacilityId?: string }) {
    const devicesAPI = new DevicesAPI();
    const { data, error } = await devicesAPI.getDevices(payload);
    if (error) {
      this.getDevicesFailure(error);
      return { error };
    } else {
      this.getDevicesSuccess(data);
    }
  }

  @Mutation
  setLoadingCreateDevice(isLoading: boolean) {
    this.isLoadingCreateDevice = isLoading;
  }
  @Mutation
  setCreateDeviceSuccess(data: any) {
    this.isLoadingCreateDevice = false;
  }
  @Mutation
  setCreateDeviceFailure(error: ErrorResponse) {
    this.errorCreateDevice = error;
    this.isLoadingCreateDevice = false;
  }

  @Action({ rawError: true })
  async createDevices(payload: { FacilityId: string; Name: string }) {
    const devicesAPI = new DevicesAPI();
    const { data, error } = await devicesAPI.postDevices(payload);
    if (error) {
      this.setCreateDeviceFailure(error);
      return { error };
    } else {
      this.setCreateDeviceSuccess(data);
      return data;
    }
  }
}
export default getModule(DeviceModule);
