import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import store from "@/store";
import { ErrorResponse } from "@/services/axios/error";
import {
  IScopeTwoChart,
  IScopeTwoDataChart,
  IScopeTwoGeneratedEnergies,
  IScopeTwoGeneratedEnergiesRequest,
  IScopeTwoGeneratedEnergiesResponse,
  IScopeTwoGeneratedEnergiesUploadRequest,
} from "@/models/ScopeTwoEmissions";
import { S2RPAPI } from "@/services/s2rp";
import { ScopeTwoEmissionAPI } from "@/services/scopeTwoEmissions";
import { covertDataChartMonthValue } from "@/utils/helpers/convertDataChart";
const name = "ScopeTwoGeneratedEnergies";

if (store.state[name]) {
  store.unregisterModule(name);
}
@Module({ dynamic: true, name, namespaced: true, store })
class S2GeneratedEnergyModule extends VuexModule {
  private errorGetGeneratedEnergies = {} as ErrorResponse;
  private isLoadingGetGeneratedEnergies = false;
  private dataGeneratedEnergies = [] as IScopeTwoGeneratedEnergiesResponse[];
  private isLoadingCreateGeneratedEnergies = false;
  private errorCreateGeneratedEnergies = {} as ErrorResponse;
  private errorScopeTwoEnergyLoad = {} as ErrorResponse;
  private dataScopeTwoEnergyLoad = [] as IScopeTwoDataChart[][];
  private isLoadingScopeTwoEmission = false as boolean;
  get isLoadingGetGeneratedEnergiesGetter() {
    return this.isLoadingGetGeneratedEnergies;
  }

  get dataGeneratedEnergiesGetter() {
    return this.dataGeneratedEnergies;
  }

  @Mutation
  setLoadingGeneratedEnergyRECs(isLoading: boolean) {
    this.isLoadingGetGeneratedEnergies = isLoading;
  }
  @Mutation
  getGeneratedEnergySuccess(data: any) {
    const { Collection, ...rest } = data;
    this.dataGeneratedEnergies = Collection;
    this.isLoadingGetGeneratedEnergies = false;
  }
  @Mutation
  getGeneratedEnergyFailure(error: ErrorResponse) {
    this.errorGetGeneratedEnergies = error;
    this.isLoadingGetGeneratedEnergies = false;
  }
  @Action({ rawError: true })
  async getGeneratedEnergies(payload?: IScopeTwoGeneratedEnergies) {
    const serviceS2GeneratedEnergiesAPI = new S2RPAPI();
    const { data, error } =
      await serviceS2GeneratedEnergiesAPI.getScopeTwoGeneratedEnergies(payload);
    if (error) {
      this.getGeneratedEnergyFailure(error);
      return { error };
    } else {
      this.getGeneratedEnergySuccess(data);
    }
  }

  @Mutation
  setLoadingCreateGeneratedEnergyRECs(isLoading: boolean) {
    this.isLoadingCreateGeneratedEnergies = isLoading;
  }
  @Mutation
  getGeneratedCreateEnergySuccess(data: any) {
    this.errorCreateGeneratedEnergies = {} as ErrorResponse;
  }
  @Mutation
  getGeneratedCreateEnergyFailure(error: ErrorResponse) {
    this.errorCreateGeneratedEnergies = error;
    this.isLoadingCreateGeneratedEnergies = false;
  }
  @Action({ rawError: true })
  async createGeneratedEnergy(payload: IScopeTwoGeneratedEnergiesRequest) {
    const serviceS2GeneratedEnergiesAPI = new S2RPAPI();
    const { data, error } =
      await serviceS2GeneratedEnergiesAPI.postScopeTwoGeneratedEnergies(
        payload
      );
    if (error) {
      this.getGeneratedEnergyFailure(error);
      return { error };
    } else {
      this.getGeneratedEnergySuccess(data);
      return data;
    }
  }

  @Mutation
  setLoadingUploadFileGeneratedEnergyRECs(isLoading: boolean) {
    this.isLoadingCreateGeneratedEnergies = isLoading;
  }
  @Mutation
  setGeneratedUploadFileEnergySuccess(data: any) {
    this.errorCreateGeneratedEnergies = {} as ErrorResponse;
  }

  @Mutation
  setGeneratedUploadFileEnergyFailure(data: any) {
    this.errorCreateGeneratedEnergies = {} as ErrorResponse;
    this.isLoadingCreateGeneratedEnergies = false;
  }
  @Action({ rawError: true })
  async uploadFileGeneratedEnergy(
    payload: IScopeTwoGeneratedEnergiesUploadRequest
  ) {
    this.setLoadingUploadFileGeneratedEnergyRECs(true);
    const serviceS2GeneratedEnergiesAPI = new S2RPAPI();
    const { data, error } =
      await serviceS2GeneratedEnergiesAPI.uploadDocumentScopeTwoGeneratedEnergies(
        payload
      );
    if (error) {
      this.getGeneratedEnergyFailure(error);
      return { error };
    } else {
      this.setGeneratedUploadFileEnergySuccess(data);
      return data;
    }
  }

  get dataScopeTwoEnergyLoadGetter() {
    return this.dataScopeTwoEnergyLoad;
  }

  get isLoadingScopeTwoEmissionGetter() {
    return this.isLoadingScopeTwoEmission;
  }
  @Mutation
  setIsLoadingS2Load(isLoading: boolean) {
    this.isLoadingScopeTwoEmission = isLoading;
  }
  @Mutation
  setS2LoadEnergyError(error: ErrorResponse) {
    this.errorScopeTwoEnergyLoad = error;
    this.isLoadingScopeTwoEmission = false;
  }

  @Mutation
  setS2LoadEnergySuccess(dataS2LoadEnergy: IScopeTwoDataChart[][]) {
    this.dataScopeTwoEnergyLoad = dataS2LoadEnergy;
    this.isLoadingScopeTwoEmission = false;
  }
  @Action({ rawError: true })
  async fetchS2RenewablePercentages() {
    this.setIsLoadingS2Load(true);
    const serviceScopeTwoEmissionAPI = new ScopeTwoEmissionAPI();
    const { data, error } =
      await serviceScopeTwoEmissionAPI.getScopeTwoEmissionsRenewablePercentages(
        { Year: new Date().getFullYear() }
      );
    if (error) {
      this.setS2LoadEnergyError(error);
      return null;
    } else {
      const dataConvert = covertDataChartMonthValue(
        data.GeneratedEnergyDetails
      );
      this.setS2LoadEnergySuccess(dataConvert);
    }
  }
}
export default getModule(S2GeneratedEnergyModule);
